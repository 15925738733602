<template>
  <div>
    <b-card
      no-body
      class="mb-0 pt-[0.2rem]"
    >
      <b-table
        ref="refDataListTable"
        class="position-relative table-data has-filter-thead"
        :items="fetchDataList"
        responsive
        hover
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        no-sort-reset
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :sticky-header="true"
        :tbody-tr-class="resolveRowClass"
        @row-dblclicked="openSingleConsumerConsumptionReport"
      >
        <!-- Filters -->
        <template #thead-top="data">
          <b-tr class="filter-thead">
            <b-th
              v-for="field in data.fields"
              :key="field.key"
            >
              <b-form-input
                v-if="field.filter"
                v-model="filterInputs[field.key]"
                class="w-full m-0"
                :placeholder="'' + field.label"
              />
            </b-th>
            <!-- <span>{{data.fields}}</span> -->
          </b-tr>
        </template>

        <!-- Virtual Column: Index -->
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>

        <!-- Column: Address -->
        <template #cell(address)="data">
          {{ resolveAddressLabel(data.item) }}
        </template>

        <!-- Column: Meter Size -->
        <template #cell(meter_size)="data">
          {{ resolveMeterSizeLabel(data.item.meter_size) }}
        </template>

        <!-- Column: Meter Model -->
        <template #cell(meter_model)="data">
          {{ resolveMeterModelLabel(data.item.meter_model) }}
        </template>

        <!-- Column: Aquifers -->
        <template #cell(aquifers)="data">
          {{ resolveAquifersLabel(data.item.aquifers) }}
        </template>

        <!-- Column: Consumption -->
        <template #cell(total_plus)="data">
          <div class="text-right">
            {{ data.item.total_plus }}
          </div>
        </template>

        <!-- Column: Reverse Flow -->
        <template #cell(total_minus)="data">
          <div class="text-right">
            {{ data.item.total_minus }}
          </div>
        </template>

        <!-- Column: Last Communication -->
        <template #cell(last_communication_at)="data">
          <div class="inline-flex justify-end w-full">
            <div class="text-right self-center">
              {{ data.item.last_communication_at }}
            </div>
          </div>
        </template>

        <!-- Column: Main Battery -->
        <template #cell(main_battery)="data">
          <div class="inline-flex justify-end w-full">
            <div class="text-right self-center">
              {{ data.item.main_battery }}
            </div>
            <img
              v-if="data.item.main_battery_state"
              class="ml-[0.2rem] w-[32px]"
              :title="data.item.main_battery_state_title"
              :src="icons['battery-' + data.item.main_battery_state]"
            >
            <img
              v-else
              class="ml-[0.2rem] w-[32px]"
              title="N/A"
              :src="icons['battery-default']"
            >
          </div>
        </template>

        <!-- Column: 3G/4G Battery -->
        <template #cell(gsm_battery)="data">
          <div class="inline-flex justify-end w-full">
            <div class="text-right self-center">
              {{ data.item.gsm_battery }}
            </div>
            <img
              v-if="data.item.gsm_battery_state"
              class="ml-[0.2rem] w-[32px]"
              :title="data.item.gsm_battery_state_title"
              :src="icons['battery-' + data.item.gsm_battery_state]"
            >
            <img
              v-else
              class="ml-[0.2rem w-[32px]"
              title="N/A"
              :src="icons['battery-default']"
            >
          </div>
        </template>

        <!-- Column: Health Status -->
        <template #cell(health_status)="data">
          <div class="inline-flex justify-end w-full">
            <span>
              <img
                v-if="data.item.customer_state_color"
                class="ml-[0.4rem] w-[32px]"
                :title="data.item.customer_state_title"
                :src="icons['disable-' + data.item.customer_state]"
              >
            </span>
            <span>
              <img
                v-if="data.item.reverse_flow_state_color"
                class="ml-[0.4rem] w-[32px]"
                :title="data.item.reverse_flow_state_title"
                :src="icons['reverse-' + data.item.reverse_flow_state]"
              >
            </span>
            <span>
              <img
                v-if="data.item.last_communication_at"
                class="ml-[0.4rem] w-[32px]"
                :title="data.item.communication_state_title"
                :src="icons['wifi-' + data.item.communication_state]"
              >
              <img
                v-else
                class="ml-[0.4rem]"
                title="N/A"
                :src="icons['wifi-default']"
              >
            </span>
          </div>
        </template>

        <!-- Column: GPS -->
        <template #cell(latitude)="data">
          <div class="text-right inline-flex">
            {{ data.item.latitude }}, {{ data.item.longitude }}
            <a
              title="Open in Google Maps"
              :href="`https://www.google.com/maps/dir//${data.item.latitude},${data.item.longitude}/@${data.item.latitude},${data.item.longitude},15z`"
              target="_blank"
            >
              <feather-icon
                class="ml-1 cursor-pointer z-10"
                icon="ExternalLinkIcon"
                size="16"
              />
            </a>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="openSideBardFor('view', data.item)">
              <feather-icon
                icon="FileTextIcon"
                class="inline"
              />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>
            <b-dropdown-item @click="openSideBardFor('update', data.item)">
              <feather-icon
                icon="EditIcon"
                class="inline"
              />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="flex flex-col mb-1 mb-md-0"
          >
            <span
              class="mb-1"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span>
            <div>
              <label>Show</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>entries</label>
            </div>
          </b-col>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BSidebar,
  BTr,
  BTh,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import appIcons from '@leecom/app-icons'

export default {
  components: {
    BCard,
    BRow,
    BTr,
    BTh,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSidebar,
    vSelect,
  },
  props: {
    module: {
      type: Object,
      required: true,
    },
    useList: {
      type: Function,
      required: true,
    },
    useSideBar: {
      type: Function,
      required: true,
    },
    moduleName: {
      type: String,
      required: true,
    },
  },
  setup(props, { root: $this, emit }) {
    const STORE_MODULE_NAME = props.moduleName
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, props.module)
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const formSuccess = value => {
      closeSideBar(!value)
      refetchData()
    }

    const openSingleConsumerConsumptionReport = item => {
      $this.$router.push({ name: 'reports-single-consumer-consumption' })
      setTimeout(() => {
        $this.$root.$emit('open-single-consumer-consumption-report', item)
      }, 1000)
    }

    const icons = ref(appIcons)

    const {
      fetchDataList,
      tableColumns,
      perPage,
      currentPage,
      totalRows,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDataListTable,
      filterInputs,

      refetchData,

      resolveAddressLabel,
      resolveMeterSizeLabel,
      resolveMeterModelLabel,
      resolveAquifersLabel,
      resolveRowClass,

      basicFilter,

      addBtnText,
    } = props.useList()

    const {
      sideBarTitle,
      sideBarActive,

      openSideBardFor,
      closeSideBar,

      formKey,
      formValues,
      formViewOnly,
    } = props.useSideBar()

    return {
      fetchDataList,
      tableColumns,
      perPage,
      currentPage,
      totalRows,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDataListTable,
      filterInputs,

      refetchData,

      resolveAddressLabel,
      resolveMeterSizeLabel,
      resolveMeterModelLabel,
      resolveAquifersLabel,
      resolveRowClass,

      basicFilter,

      addBtnText,

      sideBarTitle,
      sideBarActive,

      openSideBardFor,
      closeSideBar,

      formKey,
      formValues,
      formViewOnly,

      formSuccess,

      icons,

      openSingleConsumerConsumptionReport,
    }
  },
}
</script>
