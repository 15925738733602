<template>
  <latest-status-table
    :module="LatestStatusStoreModule"
    :use-list="useLatestStatusList"
    :use-side-bar="useSideBar"
    module-name="app-latest-status"
  />
</template>

<script>
import LatestStatusTable from './LatestStatusTable.vue'
import useLatestStatusList from './useLatestStatusList'
import useSideBar from './useSideBar'
import LatestStatusStoreModule from './LatestStatusStoreModule'

export default {
  components: {
    LatestStatusTable,
  },
  data() {
    return {
      useLatestStatusList,
      useSideBar,
      LatestStatusStoreModule,
    }
  },
}
</script>

<style></style>
