import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'
import _ from 'lodash'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { showErrorToast } from '@leecom/utils/support'

export default function useDataList() {

  const toast = useToast()
  const refDataListTable = ref(null)
  const STORE_MODULE_NAME = 'app-latest-status'
  const addBtnText = 'Add';

  // *===============================================---*
  // *--------- Data List Table ---------------------*
  // *===============================================---*
  const tableColumns = [
    { key: 'index', sortable: false, filter: false, label: '' },
    { key: 'serial_no', sortable: true, filter: true, },
    { key: 'reference_no', sortable: true, filter: true, },
    { key: 'well_no', sortable: true, filter: true, },
    { key: 'aquifers', sortable: true, filter: true, },
    { key: 'consumer_name', sortable: true, filter: true, },
    { key: 'address', sortable: false, filter: true, },
    { key: 'main_battery', sortable: false, filter: false, },
    { key: 'gsm_battery', sortable: false, filter: false, label: '3G/4G Battery' },
    { key: 'total_plus', sortable: false, filter: false, label: 'Consumption' },
    { key: 'total_minus', sortable: false, filter: false, label: 'Reverse Flow' },
    { key: 'last_communication_at', sortable: false, filter: false, label: 'Last Message Date' },
    { key: 'health_status', sortable: false, filter: false, },
    { key: 'meter_time', sortable: false, filter: false, label: 'Meter Date' },
    { key: 'meter_size', sortable: true, filter: true, },
    { key: 'meter_model', sortable: true, filter: true, },
    { key: 'latitude', sortable: false, filter: false, label: 'GPS Location' },
  ]

  const perPage = ref(10)
  const totalRows = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref(null)
  const sortBy = ref(null)
  const isSortDirDesc = ref(false)
  const basicFilter = ref(null)
  const filterInputs = ref({
    serial_no: null,
    reference_no: null,
    well_no: null,
    consumer_name: null,
    meter_size: null,
    meter_model: null,
    address: null,
    aquifers: null,
  })




  const dataMeta = computed(() => {
    const localItemsCount = refDataListTable.value ? refDataListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRows.value,
    }
  })

  const refetchData = () => {
    refDataListTable.value.refresh()
  }

  const refetchDataWithDebounce = _.debounce(() => {
    refDataListTable.value.refresh()
  }, 300);

  watch([currentPage, perPage, searchQuery, basicFilter], () => {
    refetchData()
  })

  watch(filterInputs.value, () => {
    refetchDataWithDebounce()
  })

  const resolveSample = role => {
  }

  const resolveRolesLabel = roles => {
    return roles.map(({ name }) => name).join(", ")
  }

  const resolveAddressLabel = (item) => {
    return `BLD.${item.building} RD.${item.road} BLK.${item.block} ${item.area}`
  }

  const resolveMeterSizeLabel = (value) => {
    return _.get(_.find(store.state.app.enums.MeterSizeType, { value: value }), 'label', null);
  }
  const resolveMeterModelLabel = (value) => {
    return _.get(_.find(store.state.app.enums.MeterModelType, { value: value }), 'label', null);
  }

  const resolveAquifersLabel = (value) => {
    return _.get(_.find(store.state.app.enums.AquifersType, { value: value }), 'label', null);
  }

  const resolveRowClass = (item, type) => {
    if (!item || type !== 'row') return

    if (item.customer_state !== 'normal') {
      if (item.customer_state_color == '#857D81') {
        return `!bg-[${item.customer_state_color}] text-white`;
      }
      return `!bg-[${item.customer_state_color}]`;
    }

    if (item.communication_state !== 'normal') {
      if (item.communication_state_color == '#EA5455') {
        return `!bg-[${item.communication_state_color}] text-white`;
      }
      return `!bg-[${item.communication_state_color}]`;
    }

    if (item.reverse_flow_state !== 'normal') {
      if (item.reverse_flow_state_color == '#EA5455') {
        return `!bg-[${item.reverse_flow_state_color}] text-white`;
      }
      return `!bg-[${item.reverse_flow_state_color}]`;
    }
  }

  const fetchDataList = (ctx, callback) => {
    store
      .dispatch(`${STORE_MODULE_NAME}/fetchDataList`, {
        queryParams: {
          q: searchQuery.value,
          perPage: perPage.value,
          page: currentPage.value,
          sortBy: sortBy.value,
          sortDesc: isSortDirDesc.value,
        },
        body: {
          filters: filterInputs.value,
        }
      })
      .then((response) => {

        const { data, total } = response.data.results;
        callback(data)
        totalRows.value = total
      })
      .catch((error) => {
        console.log(error);
        const { response } = error;
        showErrorToast(toast, response)
      })
  }




  return {
    // data list table
    fetchDataList,
    tableColumns,
    perPage,
    currentPage,
    totalRows,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refDataListTable,
    filterInputs,

    refetchData,

    resolveSample,
    resolveRolesLabel,
    resolveAddressLabel,
    resolveMeterSizeLabel,
    resolveMeterModelLabel,
    resolveAquifersLabel,
    resolveRowClass,

    basicFilter,

    addBtnText,
  }
}
