import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'
import _ from 'lodash'
import moment from "moment-timezone";

moment.locale("en");
moment.tz("UTC");

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { showErrorToast } from '@leecom/utils/support'

export default function useSideBar() {

    const toast = useToast()
    const sideBarTitle = ref('');
    const sideBarActive = ref(false);
    const waiting = ref(false);

    const openSideBardFor = (type, data = null) => {


        if (type == 'store') {
            sideBarTitle.value = 'Add New Meter';
            // formValues.value = {
            //     mode: 'storeMeter', Image: null,
            //     'Serial No': '12345678',
            //     'Meter Size': '2',
            //     'Meter Model': '2',
            //     'GPS Lat': '6.5913',
            //     'GPS Long': '78.23597',
            //     'Water Quality': '12313',
            //     'Reference No': 'Acc123',
            //     'Consumer Name': 'namos ofe',
            //     'Consumer Type': '2',
            //     Aquifers: '1',
            //     'Well No': '123123',
            //     'Water Source': '1',
            //     'Pump Type': '2',
            //     'CPR No': '123456789',
            //     'CR No': '12312',
            //     'Consumer Name (Arabic)': 'asd2 ionad',
            //     Building: 'asd',
            //     Block: 'asd',
            //     Road: 'asd',
            //     'Plot No': 'asd',
            //     Area: 'asd',
            //     Governorate: '2',
            //     'PO Box': '123',
            //     Mobile: '0777123456',
            //     Email: 'test@email.com',
            //     'Office Phone': '0117444123',
            // }
            formValues.value = {
                mode: 'storeMeter',
                Image: null,
            }
            formViewOnly.value = false;
        }

        if (type == 'update') {
            sideBarTitle.value = 'Edit Meter';
            const keyedData = _setKeysBeforeLoad(data);

            keyedData.mode = 'updateMeter';
            formValues.value = keyedData;
            formViewOnly.value = false;
        }

        if (type == 'view') {
            sideBarTitle.value = 'Meter Details';
            const keyedData = _setKeysBeforeLoad(data);

            keyedData.mode = null;
            formValues.value = keyedData;
            formViewOnly.value = true;
        }

        sideBarActive.value = true;
        formKey.value = moment().valueOf();

    }

    const closeSideBar = (event) => {
        sideBarActive.value = event;
        if (!event) {
            formKey.value = null;
        }
    }


    const formKey = ref(null)
    const formValues = ref({});
    const formViewOnly = ref(true);

    const submitForm = (data) => {
        _setBusy(true);
        const keyedData = _setKeysBeforeSubmit(data);
        return new Promise((resolve, reject) => {
            store.dispatch(`app-meters/${keyedData.mode}`, keyedData)
                .then((response) => {
                    _setBusy(false);
                    resolve(response);
                })
                .catch((error) => {
                    _setBusy(false);
                    console.log(error);
                    reject(error);
                    const { response } = error;
                    showErrorToast(toast, response, 'Error saving meter')
                })
        });
    }

    const uploadFile = (file, progress, error, option) => {
        const formData = new FormData()
        formData.append('image', file)

        return store
            .dispatch('app-meters/uploadImage', formData)
    }








    const _setBusy = (state) => {
        waiting.value = state;
    }

    const _setKeysBeforeSubmit = (data) => {
        const keyedData = _.mapKeys(data, (value, key) => {
            let index = key.replaceAll(' ', '_').toLowerCase();
            if (index == 'consumer_name_(arabic)') {
                index = 'consumer_name_arabic';
            }

            if (index == 'gps_lat') {
                index = 'latitude';
            }

            if (index == 'gps_long') {
                index = 'longitude';
            }
            return index;
        });

        keyedData.image = keyedData.image ? keyedData.image[0].url : null;

        return keyedData;
    }

    const _setKeysBeforeLoad = (data) => {
        console.log(data);
        const keyedData = _.mapKeys(data, (value, key) => {
            let index = key;

            if (index == 'latitude') {
                index = 'GPS_lat';
            }

            if (index == 'longitude') {
                index = 'GPS_long';
            }

            index = index.replaceAll('_', ' ');

            index = _.startCase(index);

            if (index == 'Consumer Name Arabic') {
                index = 'Consumer Name (Arabic)';
            }

            if (index == 'Cpr No') {
                index = 'CPR No';
            }
            if (index == 'Cr No') {
                index = 'CR No';
            }

            if (index == 'Po Box') {
                index = 'PO Box';
            }

            return index;
        });

        let url = keyedData.Image;

        if (url != 'https://source.unsplash.com/random') {
            const imageName = keyedData.Image.split('.')[0];
            const imageExt = keyedData.Image.split('.').pop();
            url = `${store.state.appConfig.layout.assetsPath}/images/customers/${imageName}-400.${imageExt}`;
        }

        keyedData.Image = [{
            url: url,
        }]

        keyedData.showImage = url;

        return keyedData;
    }

    return {
        sideBarTitle,
        sideBarActive,

        openSideBardFor,
        closeSideBar,

        formKey,
        formValues,
        formViewOnly,

        waiting,

        uploadFile,
        submitForm,
    }
}